<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{ errors }}</Message>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="period">Periode</label>
                                <Calendar selectionMode="range" v-tooltip.top="'Maksimal 30 hari'" :manualInput="false"
                                    v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar"
                                    :showIcon="true" />
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label>Medrep</label>
                                <Dropdown v-model="filters.id_mr" :loading="loadingDropdownMedrepFilter"
                                    :options="dataDropdownMedrepFilter" optionLabel="label" optionValue="id_mr"
                                    placeholder="Pilih Medrep" :filter="true" :showClear="true"
                                    @filter="searchDropdownMedrep($event, 'filter')" />
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1"
                        @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="se_id"
                    @sort="onSort($event)" :resizableColumns="true">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{ width: '20px' }">
                        <template #body="slotProps">
                            {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="upload_at_date" header="Upload Date">
                        <template #body="slotProps">
                            {{ slotProps.data.upload_at_date }}
                        </template>
                    </Column>
                    <Column field="upload_at_time" header="Upload Time">
                        <template #body="slotProps">
                            {{ slotProps.data.upload_at_time }}
                        </template>
                    </Column>
                    <Column field="approval_date" header="Approval Date">
                        <template #body="slotProps">
                            {{ slotProps.data.approval_date }}
                        </template>
                    </Column>
                    <Column field="approval_time" header="Approval Time">
                        <template #body="slotProps">
                            {{ slotProps.data.approval_time }}
                        </template>
                    </Column>
                    <Column field="visited_date" header="Visited Date">
                        <template #body="slotProps">
                            {{ slotProps.data.visited_date }}
                        </template>
                    </Column>
                    <Column field="visited_time" header="Visited Time">
                        <template #body="slotProps">
                            {{ slotProps.data.visited_time }}
                        </template>
                    </Column>
                    <Column field="kode_mr" header="Username">
                        <template #body="slotProps">
                            {{ slotProps.data.kode_mr }}
                        </template>
                    </Column>
                    <Column field="name_mr" header="Name">
                        <template #body="slotProps">
                            {{ slotProps.data.name_mr }}
                        </template>
                    </Column>
                    <Column field="callplan_date" header="Callplan Date">
                        <template #body="slotProps">
                            {{ slotProps.data.callplan_date }}
                        </template>
                    </Column>
                    <Column field="waktu" header="Waktu">
                        <template #body="slotProps">
                            {{ slotProps.data.waktu }}
                        </template>
                    </Column>
                    <Column field="task" header="Task Name">
                        <template #body="slotProps">
                            {{ slotProps.data.task }}
                        </template>
                    </Column>
                    <Column field="name_dokter" header="Nama Customer">
                        <template #body="slotProps">
                            {{ slotProps.data.name_dokter }}
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{ totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount"
                    :rowsPerPageOptions="[10, 20, 30]">
                    <template>
                        {{ totalItemsCount }}
                    </template>
                </Paginator>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            loadingDropdownMedrepFilter: false,

            // dataDropdown
            dataDropdownMedrepFilter: null,

            // filter
            period: [new Date(), new Date()],
            maxDate: null,
            filters: {
                id_mr: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownMedrep('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
        period() {
            //max range 30 days
            this.maxDate = new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed: {
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
        period_start_label() {
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD');
        },
        period_end_label() {
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD');
        },
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownMedrep(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dmdrp.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownMedrepFilter = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-medrep',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownMedrepFilter = res.data.data;
                            this.loadingDropdownMedrepFilter = false;
                        } else if (purpose == null) {
                            this.dataDropdownMedrepFilter = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        // DATATABLE
        getDataTable() {

            if (
                this.period_start_label !== null &&
                this.period_end_label !== null &&
                this.period_start_label !== 'Invalid date' &&
                this.period_end_label !== 'Invalid date'
            ) {

                this.loading = true;
                this.page = (this.offset / this.rows) + 1;

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/report-callplan',
                    params: {
                        "search": this.search,
                        "per_page": this.rows,
                        "page": this.page,
                        "order_by": this.field,
                        "sort_by": this.sort,
                        "id_mr": this.filters.id_mr,
                        "start_date": this.period_start_label,
                        "end_date": this.period_end_label,
                    }
                })
                    .then(res => {
                        this.data = res.data.data.data;
                        this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                        this.totalItemsCount = res.data.data.total;
                        this.rows = parseInt(res.data.data.per_page);
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }

        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext) {

            if (
                this.period_start_label !== null &&
                this.period_end_label !== null &&
                this.period_start_label !== 'Invalid date' &&
                this.period_end_label !== 'Invalid date'
            ) {


                if (ext == 'xlsx') {
                    this.loadingExcel = true;
                } else if (ext == 'csv') {
                    this.loadingCsv = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/report-callplan/export',
                    responseType: 'blob',
                    params: {
                        "ext": ext,
                        "id_mr": this.filters.id_mr,
                        "start_date": this.period_start_label,
                        "end_date": this.period_end_label,
                    }
                })
                    .then(response => {
                        let fileUrl = window.URL.createObjectURL(response.data);
                        let fileLink = document.createElement('a');

                        fileLink.href = fileUrl;

                        fileLink.setAttribute('download', 'Report Call Plan Export.' + ext);

                        document.body.appendChild(fileLink)

                        fileLink.click();

                        if (ext == 'xlsx') {
                            this.loadingExcel = false;
                        } else if (ext == 'csv') {
                            this.loadingCsv = false;
                        }

                    })
                    .catch((err) => {
                        console.log(err);

                        if (ext == 'xlsx') {
                            this.loadingExcel = false;
                        } else if (ext == 'csv') {
                            this.loadingCsv = false;
                        }
                    });
            } else {
                alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        },
    }
}
</script>